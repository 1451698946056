import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import SVG from './SVG';
import 'slick-carousel/slick/slick.css';
import './slider.scss';

const settings = {
  dots: false,
  lazyLoad: true,
  infinite: true,
  speed: 1000,
  fade: true,
  autoplay: true,
  autoplaySpeed: 10000,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 1,
  adaptiveHeight: false,
  accessibility: true,
  arrows: true,
  swipe: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} custom-arrow next`} style={{ ...style }} onClick={onClick}>
      <SVG icon="right" viewBox="6 0 12 24" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={`${className} custom-arrow prev`} style={{ ...style }} onClick={onClick}>
      <SVG icon="left" viewBox="6 0 12 24" />
    </div>
  );
}

const CustomSlider = ({ children, config }) => {
  const sliderConfig = {...settings, ...config};

  return <Slider {...sliderConfig}>{children}</Slider>;
};

CustomSlider.propTypes = {
  config: PropTypes.object,
};

export default CustomSlider;
