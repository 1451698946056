import React from 'react';
import PropTypes from 'prop-types';
import MemoVideoPlayer from '../../Common/VideoPlayer/VideoPlayer';

const TheBestGuidance = ({ title, description, videoURL, buttonLink }) => (
  <section className="row align-items-center py-5">
    <div className="col col-12 col-md-5 order-1 order-md-0">
      <h3 className="text-primary mb-3">{title}</h3>
      <p className="mb-4">{description}</p>
      {/* buttonLink && <a href={buttonLink.path} className="mt-4 btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">{buttonLink.label}</a> */}
    </div>
    {videoURL &&
      <div className="col col-12 col-md-7 mb-4 mb-md-0">
        <MemoVideoPlayer
          video={{url: videoURL, platform: 'vimeo'}}
          autoplay={true}
          hasControl={false}
          loop={true}
          title={false}
          muted={true}
        />
      </div>
    }

    <div className="col col-12 d-flex justify-content-center mt-4">
        <a href="https://totallylessons.com/sign-up" class="btn-tg btn-tg-sale" target="_blank" rel="noopener noreferrer">Get Free Access Today</a>
    </div>

  </section>
);

TheBestGuidance.propTypes = {
  title: PropTypes.string,
  VideoURL: PropTypes.object,
  description: PropTypes.string,
  buttonLink: PropTypes.object,
};

export default TheBestGuidance;
